import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { KEY_WORD, PRODUCTS, fileExtension, fileType, COOKIES_LIST } from './constant';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import { async } from 'q';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export const secretKey = 'democlient:democlientsecret';
export const encryptKey = 'y347t567t347InCrUiTeRb6t5b3487y5';
export const isUpperCase = (value) => /[A-Z]/.test(value);
export const isLowerCase = (value) => /[a-z]/.test(value);
export const isContainNumber = (value) => /\d/.test(value);
export const isSpecialChar = (value) => /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(value);
const envMode = process.env.REACT_APP_ENV;
export const getLocalStorageItem = (key) => localStorage.getItem(key);
export const setLocalStorageItem = (key, value) => localStorage.setItem(key, value);
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const convertIntoQueryString = (data) => {
    return qs.stringify(data);
};

export const checkFileValidation = (files, type) => {
    let isValid = false;
    let message = 'File type not supported. Only .doc, .docx, and .pdf are allowed.';
    let zipMessage =
        'Invalid File Format: Please upload a valid zip file. Only files with the .zip extension are allowed.';

    let xlsxMessage =
        'Invalid File Format: Please upload a valid xlsx file. Only files with the .xlsx extension are allowed.';

    if (type === 'image') {
        if (files.type === 'image/jpeg' || files.type === 'image/png' || files.type === 'image/jpg') {
            isValid = true;
        }
    } else if (type === 'zip') {
        if (
            files.type === 'zip' ||
            files.type === 'application/octet-stream' ||
            files.type === 'application/zip' ||
            files.type === 'application/x-zip' ||
            files.type === 'application/x-zip-compressed'
        ) {
            isValid = true;
        }
    } else if (type === 'xlsx') {
        if (files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            isValid = true;
        }
    } else {
        if (
            files.type === 'application/pdf' ||
            files.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            files.type === 'application/msword'
        ) {
            isValid = true;
        }
    }
    if (!isValid) {
        if (type === 'zip') {
            return {
                status: isValid,
                msg: zipMessage,
            };
        } else if (type === 'xlsx') {
            return {
                status: isValid,
                msg: xlsxMessage,
            };
        } else {
            return {
                status: isValid,
                msg: message,
            };
        }
    } else {
        return {
            status: isValid,
            msg: '',
        };
    }
};

export const checkZipFileSize = (file, type) => {
    if (file.size > 50 * 1024 * 1024) {
        if (type === 'zip') {
            return {
                status: false,
                msg: 'The Zip file size exceeds the 50MB limit',
            };
        } else if (type === 'xlsx') {
            return {
                status: false,
                msg: 'The Xlsx file size exceeds the 50MB limit',
            };
        }
    } else {
        return {
            status: true,
            msg: '',
        };
    }
};

export const checkFileBlank = async (file) => {
    if (file) {
        if (file.size > 5 * 1024 * 1024) {
            // Check if file size is greater than 10 MB
            return {
                status: false,
                msg: 'The file size exceeds the 5MB limit',
            };
        }
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(
                `${generateUrl('interview_backend')}/interviews/fileValidator`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${getAccessToken}`,
                    },
                }
            );
            return { status: response?.data?.status, msg: 'The uploaded file is empty' };
        } catch (error) {
            return {
                status: 'error',
                msg: 'An error occurred during file validation',
            };
        }
    } else {
        return { status: 'error', msg: 'File is empty or undefined' };
    }
};

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const capitalizeAll = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
export const useOutsideAlerter = (ref, setIsOpen) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setIsOpen]);
};

export const Toast = ({ msg }) => <>{msg}</>;

export const getYearsFrom1900ToCurrentYear = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const totalYear = currentYear - 1900;
    const year = [];
    for (let i = totalYear; i >= 0; i--) {
        year.push(currentYear - i);
    }
    return year;
};
const date = new Date();
const year = date.toISOString().split('-')[0];
const month = date.toISOString().split('-')[1];
export const graphDropDownInterviewMonthsOption = [
    {
        value: `&from=2013-01-01T00:00:00.000Z&to=${year}-${month}-31T23:59:59.000Z`,
        label: 'All',
    },
    {
        value: `&from=${year}-01-01T00:00:00.000Z&to=${year}-01-31T23:59:59.000Z`,
        label: 'Jan',
    },
    {
        value: `&from=${year}-02-01T00:00:00.000Z&to=${year}-02-28T23:59:59.000Z`,
        label: 'Feb',
    },
    {
        value: `&from=${year}-03-01T00:00:00.000Z&to=${year}-03-31T23:59:59.000Z`,
        label: 'Mar',
    },
    {
        value: `&from=${year}-04-01T00:00:00.000Z&to=${year}-04-30T23:59:59.000Z`,
        label: 'Apr',
    },
    {
        value: `&from=${year}-05-01T00:00:00.000Z&to=${year}-05-31T23:59:59.000Z`,
        label: 'May',
    },
    {
        value: `&from=${year}-06-01T00:00:00.000Z&to=${year}-06-30T23:59:59.000Z`,
        label: 'Jun',
    },
    {
        value: `&from=${year}-07-01T00:00:00.000Z&to=${year}-07-31T23:59:59.000Z`,
        label: 'Jul',
    },
    {
        value: `&from=${year}-08-01T00:00:00.000Z&to=${year}-08-31T23:59:59.000Z`,
        label: 'Aug',
    },
    {
        value: `&from=${year}-09-01T00:00:00.000Z&to=${year}-09-30T23:59:59.000Z`,
        label: 'Sep',
    },
    {
        value: `&from=${year}-10-01T00:00:00.000Z&to=${year}-10-31T23:59:59.000Z`,
        label: 'Oct',
    },
    {
        value: `&from=${year}-11-01T00:00:00.000Z&to=${year}-11-30T23:59:59.000Z`,
        label: 'Nov',
    },
    {
        value: `&from=${year}-12-01T00:00:00.000Z&to=${year}-12-31T23:59:59.000Z`,
        label: 'Dec',
    },
];
export const TimeRangeOptions = [
    {
        label: 'Today',
        value: {
            from: moment().format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'Yesterday',
        value: {
            from: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'Last 7 Days',
        value: {
            from: moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'Last 30 Days',
        value: {
            from: moment().subtract(30, 'days').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
    {
        label: 'All',
        value: {
            from: moment().subtract(10, 'years').format('YYYY-MM-DDT00:00:00.000Z'),
            to: moment().format('YYYY-MM-DDT23:59:59.000Z'),
        },
    },
];
export const getOptions = (data) => {
    const newArray = [];
    data.forEach((element) =>
        newArray.push({
            value: element,
            label: element,
        })
    );
    return newArray;
};

export const currentProduct = () => {
    const currentProductName = localStorageJsonParseDecrypt('productId')?.productSubDomain;
    if (currentProductName === 'InCFeed') {
        return 'IncFeed';
    } else {
        return currentProductName;
    }
};

export const localStorageName = [
    { key: 'token', value: 'iCtK' },
    { key: 'viewtype', value: 'viewType' },
    { key: 'refreshToken', value: 'iCrT' },
    { key: 'incserveToken', value: 'iCiT' },
    { key: 'incserveTokenKey', value: 'iCiTi' },
    { key: 'credit', value: 'iCcR' },
    { key: 'expiryTime', value: 'iCeT' },
    { key: 'feedbackReport', value: 'iCfd' },
    { key: 'interviewData', value: 'intDat' },
    { key: 'interviewFilter', value: 'inTft' },
    { key: 'userData', value: `iCuD_${envMode}` },
    { key: 'companyData', value: `iCcD_${envMode}` },
    { key: 'productId', value: `iCpD_${envMode}` },
    { key: 'permissions', value: `ic_p_${envMode}` },
    { key: 'inviteVideoType', value: 'RTaYR' },
];

export const exportToCSV = (apiData, fileName) => {
    if (apiData?.length > 0) {
        const objectMaxLength = [];
        const wscols = [];
        for (let i = 0; i < apiData.length; i++) {
            let value = Object.values(apiData[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] === 'number') {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] = objectMaxLength[j] >= value[j]?.length ? objectMaxLength[j] : value[j]?.length;
                }
            }
        }

        for (let i = 0; i < Object?.keys(apiData[0]).length; i++) {
            wscols.push({ width: objectMaxLength[i] });
        }

        const ws = XLSX.utils.json_to_sheet(apiData);
        ws['!cols'] = wscols;
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    } else {
        toast.error('No Record to Export', {
            autoClose: 5000,
            toastId: 'No Record to Export',
        });
    }
};

export const downloadFile = (fileUrl, fileName) => {
    let a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    a.target = '_blank';
    a.click();
};

export const accessRoutes = (resource) => {
    let userType = localStorageJsonParseDecrypt('userData') && localStorageJsonParseDecrypt('userData').userType;
    let permissions = localStorageJsonParseDecrypt('permissions');
    permissions = permissions && permissions.filter((itm) => itm.name === resource);
    if (permissions[0]?.status === 'active' || userType === 'employer') {
        return true;
    } else {
        return false;
    }
};

export const checkRoutes = (resource) => {
    let userType = localStorageJsonParseDecrypt('userData') && localStorageJsonParseDecrypt('userData').userType;
    let permissions = localStorageJsonParseDecrypt('permissions');
    permissions = permissions && permissions.filter((itm) => itm.name === resource);
    if (permissions[0]?.status === 'active' || userType === 'employer') {
        return true;
    } else {
        return false;
    }
};

export const redirectPermission = () => {
    let url = window.location.href;
    if (url.includes('/my-interviews') && (url.includes('incfeed') || url.includes('incvid'))) {
        const redirectUriAccount = `${generateUrl('account')}/my-interviews`;
        if (url.includes('code')) {
            let customUrl = url.split('?');
            let currentUrl = redirectUriAccount + '?' + customUrl[1];
            window.location.replace(currentUrl);
        } else if (url.includes('state') && url.includes('access_token')) {
            let customUrl = url.split('#');
            let currentUrl = redirectUriAccount + '#' + customUrl[1];
            window.location.replace(currentUrl);
        } else {
            localStorage.clear();
            clearCookies();
            window.location.replace(mainWebsite + '/employer/login');
        }
    } else {
        localStorage.clear();
        clearCookies();
        window.location.replace(mainWebsite + '/employer/login');
    }
};

const commonLocalStorageDecryptCondition = (storageKey, isJson) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    var data = '';
    if (COOKIES_LIST.includes(storageKey)) {
        if (Cookies.get(Key)) {
            var bytes = CryptoJS.AES.decrypt(Cookies.get(Key), encryptKey);
            data = bytes.toString(CryptoJS.enc.Utf8);
            if (data && isJson) {
                data = JSON.parse(data);
            }
        }
    } else {
        if (getLocalStorageItem(Key)) {
            var bytes = CryptoJS.AES.decrypt(getLocalStorageItem(Key), encryptKey);
            data = bytes.toString(CryptoJS.enc.Utf8);
            if (data && isJson) {
                data = JSON.parse(data);
            }
        }
    }
    return data;
};

export const localStorageEncrypt = (storageKey, data) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    var value = CryptoJS.AES.encrypt(data, encryptKey).toString();
    if (COOKIES_LIST.includes(storageKey)) {
        Cookies.set(Key, value, {
            domain: window.location.hostname === KEY_WORD?.LOCALHOST ? KEY_WORD?.LOCALHOST : KEY_WORD?.INCRUITER_DOMIAN,
        });
    } else {
        setLocalStorageItem(Key, value);
    }
};

export const localStorageDecrypt = (storageKey) => {
    return commonLocalStorageDecryptCondition(storageKey, false);
};

export const localStorageJsonParseDecrypt = (storageKey) => {
    return commonLocalStorageDecryptCondition(storageKey, true);
};

export const localStorageRemove = (storageKey) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    localStorage.removeItem(Key);
};

export const ArrayForExpRange = () => {
    const tmpArray = [];
    Array.from({ length: 41 }, (_, index) => index).map((itm) => {
        tmpArray.push({
            value: itm,
            label: itm,
        });
    });
    return tmpArray;
};

export const hasPageAccess = (permissions, mainResource, resourceName, action) => {
    const userType = localStorageJsonParseDecrypt('userData')?.userType;

    if (userType === 'employer') {
        return true;
    }

    if (mainResource === '') {
        return true;
    }

    const mainResourcePermission = permissions?.find(
        (permission) => permission?.key?.toLowerCase() === mainResource?.toLowerCase()
    );

    if (mainResourcePermission?.status) {
        const resourcePermission = mainResourcePermission?.resources?.find(
            (resource) => resource?.key?.toLowerCase() === resourceName?.toLowerCase()
        );
        if (resourcePermission?.actions?.hasOwnProperty('status')) {
            return resourcePermission?.actions?.status;
        } else {
            return resourcePermission?.actions[action];
        }
    } else {
        return false;
    }
};

export const hasProductAccess = (permissions, mainResourceName) => {
    const userType = localStorageJsonParseDecrypt('userData')?.userType;
    if (mainResourceName === 'incserve' || userType === 'employer') {
        return true;
    }
    const resourcePermission = permissions?.find(
        (permission) => permission?.name && permission?.name.toLowerCase() === mainResourceName?.toLowerCase()
    );
    return resourcePermission?.status || false;
};

export const hasActionAccess = (permissions, mainResource, resourceName, action) => {
    const userType = localStorageJsonParseDecrypt('userData')?.userType;

    if (userType === 'employer') {
        return true;
    }

    if (mainResource === '') {
        return true;
    }

    const mainResourcePermission = permissions?.find(
        (permission) => permission?.key?.toLowerCase() === mainResource?.toLowerCase()
    );

    const resourcePermission = mainResourcePermission?.resources?.find(
        (resource) => resource?.key?.toLowerCase() === resourceName?.toLowerCase()
    );

    if (resourcePermission?.actions?.hasOwnProperty('status')) {
        return resourcePermission?.actions?.status;
    } else if (resourcePermission?.actions) {
        return resourcePermission?.actions[action];
    }

    return false;
};
export const Rating = {
    1: 'Very Poor',
    2: 'Very Poor',
    3: 'Very Poor',
    4: 'Poor',
    5: 'Average',
    6: 'Good',
    7: 'Very Good',
    8: 'Very Good',
    9: 'Excellent',
    10: 'Excellent',
};

export const nameChecker = (value) => {
    return value?.replace(/[^A-Za-z\s\.]/g, '');
};

export const roleNameChecker = (value) => {
    return value?.replace(/[^a-zA-Z0-9\s\.,()\-_+:]/g, '');
};

export const numberChecker = (value) => {
    return value?.replace(/\D/g, '');
};

export const mailChecker = (value) => {
    return value?.replace(/[^a-zA-Z0-9\_\-\@\.\+]/g, '');
};

export const contactNumberChecker = (value) => {
    return value?.replace(/[^0-9]/g, '').slice(0, 10);
};

export const contactNumberInternationalChecker = (value) => {
    return value?.replace(/[^0-9\+]/g, '').slice(0, 14);
};

// export const remarksChecker = (value) => {
//   return value.replace(
//     /[^A-Za-z0-9\s\!\@\#\$\%\&\*\(\)\_\-\+\=\[\]\{\}\/\?\.\,\;\:\'\"\/]/g,
//     ""
//   );
// };
export const remarksChecker = (value) => {
    // Remove any characters that are not allowed, excluding new lines
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s\n!@#$%&*()_\-+=\[\]{}\/?.,;:'"\/]/g, '');
    return sanitizedValue;
};

export const addressChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\@\#\%\&\*\-\.\,\:\'\"\/]/g, '');
};

export const skillChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\,\(\)\!\@\*\.\-\+\#]/g, '');
};

export const positionNameChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\#\(\)\@\!\*\.\+]/g, '');
};

export const departmentChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\,\(\)\.]/g, '');
};

export const companyNameChecker = (value) => {
    return value.replace(/[^A-Za-z0-9\s\,\-\;\:\.\#\$\@\&]/g, '');
};

export const notificationRedirect = (data, productData, navigate) => {
    const getPath = (productName, itemType) => {
        const itemTypeLower = itemType?.toUpperCase().trim();
        switch (itemTypeLower) {
            case 'INTERVIEWS':
                if (productName === PRODUCTS.INC_BOT) return '/candidates';
                return '/my-interviews';
            case 'QUESTIONS':
                if (productName === PRODUCTS.INC_BOT) return '/positions';
                return '/my-positions';
            case 'POSITIONS':
                if (productName === PRODUCTS.INC_BOT) return '/positions';
                return '/my-positions';
            case 'CANDIDATEBANK':
                if (productName === PRODUCTS.INC_BOT) return '/candidate-bank';
            case 'SUPPORT':
                return '/support';
            case 'PANELISTS':
                return '/create-panelist';
            case 'ROLES':
                return '/roles-subuser-list?role';
            case 'SUBUSERS':
                return '/roles-subuser-list';
            default:
                return '';
        }
    };
    const getOperator = (urlPath) => {
        return urlPath.includes('?') ? '&' : '?';
    };
    const search = data?.data?.search;
    const itemType = data?.data?.itemType;
    const urlPath = getPath(data?.productId?.name, itemType);
    if (urlPath) {
        const productName = data.productId?.name;
        if (data?.data?.itemType && productName?.toLowerCase() === PRODUCTS.INC_BOT?.toLowerCase())
            window.location.href = `${generateUrl('incbot')}${urlPath}?search=${encodeURIComponent(search)}`;
        else {
            if (productName) {
                const {
                    _id: productId,
                    status: isActive,
                    subdomain: productSubDomain,
                    productHighlights: productFeatures,
                } = productData?.find((data) => data?.name?.toLowerCase() === productName?.toLowerCase());
                localStorageEncrypt(
                    'productId',
                    JSON.stringify({
                        productId,
                        isActive,
                        productSubDomain,
                        productFeatures,
                        productName,
                    })
                );
            }
            navigate(urlPath + `${getOperator(urlPath)}search=${encodeURIComponent(search)}`);
        }
    }
};

export const mailContent = (product, data, userData, companyData) => {
    let content = {
        interviewerHeader: '',
        interviewerBody: '',
        candidateHeader: '',
        candidateBody: '',
        interviewerLink: '',
        candidateLink: '',
    };
    if (product === 'InCFeed') {
        if (data?.meetInviteDetails?.interviewerMeetingLink) {
            content.interviewerHeader = `Hi ${data?.panelist?.name ?? '{{Interviewer Name}}'},\n\n${
                companyData?.name ?? '{{Company Name}}'
            } has  rescheduled an interview with a Candidate ${data?.code ?? '{{Interview ID}}'}-${
                data?.candidate?.name ?? '{{Candidate Name}}'
            } for the position of ${data?.position?.title ?? '{{Position Name}}'}.`;

            content.interviewerBody = `Instructions:-\n-Use of Chrome Browser is mandatory.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings\n-Login 5 mins early to ensure all of the above are in place.\n-Kindly input the Interview Key, which has been provided in the Email/WhatsApp at the time of login into extension.`;

            content.interviewerLink = `Below are the updated details.\nMeeting  link: ${
                data?.meetInviteDetails?.interviewerMeetingLink ?? '{{Meeting Link}}'
            }\nInterview Key : ${
                data?.interviewerKey ?? '{{Interview Key}}'
            }\nFor submitting feedback, Kindly add the extension on your chrome browser from the link below and use the pin to login into the extension.\nExtension Link: https://chrome.google.com/webstore/detail/incfeed/hbkjfklhbhbobnioodpnkcfpabljgipe`;

            content.candidateHeader = `Hi ${data?.candidate?.name ?? '{{Candidate Name}}'},\n\nYour interview for the ${
                data?.position?.title ?? '{{Position Name}}'
            } position at ${companyData?.name ?? '{{Company Name}}'} has been rescheduled on ${
                data?.interviewDate ? moment(data?.interviewDate).format('DD/MM/YY') : '{{Interview Date}}'
            }. Your Interview ID is ${data?.code ?? '{{Interview ID}}'}.`;

            content.candidateBody = `Instructions:-\n-Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings.\n-Login 5 mins early to ensure all of the above are in place.`;

            content.candidateLink = `Please find the updated details below to join the meeting:-\nMeeting  link: ${
                data?.meetInviteDetails?.candidateMeetingLink ?? '{{Meeting Link}}'
            }`;
        } else {
            content.interviewerHeader = `Hi ${data?.panelist?.name ?? '{{Interviewer Name}}'},\n\n${
                companyData?.name ?? '{{Company Name}}'
            } has scheduled an interview with a Candidate ${data?.code ?? '{{InterviewID}}'}-${
                data?.candidate?.name ?? '{{Name}}'
            } for the position of ${data?.position?.title ?? '{{Position Name}}'}.\n\nFor submitting feedback, kindly add the extension on your chrome browser from the link below and use the pin to login into the extension.`;

            content.interviewerBody = `Instructions:-\n-Use of Chrome Browser is mandatory.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings.\n-Login 5 mins early to ensure all of the above are in place.\n-Kindly input the Interview Key, which has been provided in the Email/WhatsApp at the time of login into extension.`;

            content.interviewerLink = `Below are the updated details.\nMeeting  link: ${
                data?.meetInviteDetails?.interviewerMeetingLink ?? '{{Meeting Link}}'
            }\nInterview Key : ${
                data?.interviewerKey ?? '{{Interview Key}}'
            }\nFor submitting feedback, Kindly add the extension on your chrome browser from the link below and use the pin to login into the extension.\nExtension Link: https://chrome.google.com/webstore/detail/incfeed/hbkjfklhbhbobnioodpnkcfpabljgipe`;

            content.candidateHeader = `Hi ${
                data?.candidate?.name ?? '{{Candidate Name}}'
            }\n\nWe hope you are doing well. We are delighted to extend an invitation to interview for the position of ${
                data?.position?.title ?? '{{Position Name}}'
            } at ${companyData?.name ?? '{{Company Name}}'}. Your Interview ID is ${data?.code ?? '{{InterviewID}}'}.`;

            content.candidateBody = `Instructions:-\n-Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings.\n-Login 5 mins early to ensure all of the above are in place.`;

            content.candidateLink = `Please find the updated details below to join the meeting:-\nMeeting  link: ${
                data?.meetInviteDetails?.candidateMeetingLink ?? '{{Meeting Link}}'
            }`;
        }
        return content;
    } else if (product === 'InCVid') {
        if (data?.meetInviteDetails?.interviewerMeetingLink) {
            content.interviewerHeader = `Hi ${data?.panelist?.name ?? '{{Interviewer Name}}'},\n\n${
                companyData?.name ?? '{{Company Name}}'
            } has  rescheduled an interview with a Candidate ${data?.code ?? '{{Interview ID}}'}-${
                data?.candidate?.name ?? '{{Name}}'
            } for the position of ${data?.position?.title ?? '{{Position Name}}'}.`;

            content.interviewerBody = `Instructions:-\n-Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings\n-Login 5 mins early to ensure all of the above are in place.\n-To start the interview, you have to enter the Interview Key provided in the Email/WhatsApp.`;

            content.interviewerLink = `Please find the details below to join the meeting:-\nMeeting link: ${
                data?.meetInviteDetails?.interviewerMeetingLink ?? '{{Meeting Link}}'
            }\nInterview Key : ${data?.interviewerKey ?? '{{Interview Key}}'}`;

            content.candidateHeader = `Hi ${data?.candidate?.name ?? '{{Candidate Name}}'},\n\nYour interview for the ${
                data?.position?.title ?? '{{Position Name}}'
            } position at ${companyData?.name ?? '{{Company Name}}'} has been rescheduled on ${
                data?.interviewDate ? moment(data?.interviewDate).format('DD/MM/YY') : '{{Scheduled Date}}'
            }. Your Interview ID is ${data?.code ?? '{{Interview ID}}'}`;

            content.candidateBody = `Instructions:-\n-Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings.\n-Login 5 mins early to ensure all of the above are in place.\n-The interview will be recorded for monitoring and quality purposes.`;

            content.candidateLink = `Please find the updated details below to join the meeting:-\nMeeting  link: ${
                data?.meetInviteDetails?.candidateMeetingLink ?? '{{Meeting Link}}'
            }`;
        } else {
            content.interviewerHeader = `Hi ${data?.panelist?.name ?? '{{Interviewer Name}}'},\n\n${
                companyData?.name ?? '{{Company Name}}'
            } has scheduled an interview with a Candidate ${data?.code ?? '{{InterviewID}}'}-${
                data?.candidate?.name ?? '{{Name}}'
            } for the position of ${data?.position?.title ?? '{{Position Name}}'}.`;

            content.interviewerBody = `Instructions:-\n-Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings\n-Login 5 mins early to ensure all of the above are in place.\n-To start the interview, you have to enter the Interview Key provided in the Email/WhatsApp.`;

            content.interviewerLink = `Please find the details below to join the meeting:-\nMeeting link: ${
                data?.meetInviteDetails?.interviewerMeetingLink ?? '{{Meeting Link}}'
            }\nInterview Key : ${data?.interviewerKey ?? '{{Interview Key}}'}`;

            content.candidateHeader = `Hi ${
                data?.candidate?.name ?? '{{Candidate Name}}'
            },\n\nWe hope you are doing well. We are delighted to extend an invitation to interview for the position of ${
                data?.position?.title ?? '{{Position Name}}'
            } at ${companyData?.name ?? '{{Company Name}}'}. Your Interview ID is ${data?.code ?? '{{InterviewID}}'}.`;

            content.candidateBody = `Instructions:-\n-Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n-Use your laptop and not a smartphone for the Interview.\n-Check for good internet connectivity to avoid glitches and lag during the Interview.\n-Please ensure you are free from any noisy background or surroundings.\n-Login 5 mins early to ensure all of the above are in place.\n-Interview will be recorded for monitoring and quality purposes.`;

            content.candidateLink = `Please find the updated details below to join the meeting:-\nMeeting  link: ${
                data?.meetInviteDetails?.candidateMeetingLink ?? '{{Meeting Link}}'
            }`;
        }
        return content;
    }
};
export const generateUrl = (urlMode) => {
    const envMode = process.env.REACT_APP_ENV;
    let microsoftKey, frontendUrlPreFix, backendUrlPreFix, externalUrl, aiBackendUrlPrefix;
    let finalUrl;
    if (envMode === 'prod') {
        microsoftKey = '9d01c478-e484-41d0-895e-cb5bab09b0c5';
        frontendUrlPreFix = '';
        backendUrlPreFix = 'api.';
        externalUrl = '';
        aiBackendUrlPrefix = '';
    } else if (envMode === 'test' || envMode === 'preprod') {
        microsoftKey = '6749be51-045f-4805-a00d-3c323138b754';
        frontendUrlPreFix = 'test.v1.';
        backendUrlPreFix = 'test.api.';
        externalUrl = 'preprod.';
        aiBackendUrlPrefix = 'test-';
    } else {
        microsoftKey = '6749be51-045f-4805-a00d-3c323138b754';
        frontendUrlPreFix = 'dev.v1.';
        backendUrlPreFix = 'dev.api.';
        externalUrl = 'dev.';
        aiBackendUrlPrefix = 'dev-';
    }
    if (urlMode === 'incserve') {
        finalUrl = `https://${externalUrl}incserve.incruiter.com`;
    } else if (urlMode === 'website') {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com`;
    } else if (urlMode === 'account') {
        finalUrl = `https://${frontendUrlPreFix}account.incruiter.com`;
    } else if (urlMode === 'incbot') {
        finalUrl = `https://${frontendUrlPreFix}incbot.incruiter.com`;
    } else if (urlMode === 'calendar') {
        finalUrl = `https://${frontendUrlPreFix}calendar.incruiter.com`;
    } else if (urlMode === 'career_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/candidate-system/api/v1`;
    } else if (urlMode === 'interview_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/interview-system/api/v1`;
    } else if (urlMode === 'interview_backend_incbot') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/interview-system/api/v1/incbot`;
    } else if (urlMode === 'skills_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/skills-system/api/v1`;
    } else if (urlMode === 'user_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/user-auth-system/api/v1`;
    } else if (urlMode === 'calendar_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/calendar-system/api/v1`;
    } else if (urlMode === 'subscription_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/subscription-system/api/v1`;
    } else if (urlMode === 'video_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1`;
    } else if (urlMode === 'ai_backend') {
        finalUrl = `https://${aiBackendUrlPrefix}interview-system.incruiter.com/api/v1`;
    } else if (urlMode === 'microsoftKey') {
        finalUrl = microsoftKey;
    }
    return finalUrl;
};
export const url = generateUrl('user_backend');
export const incserveUrl = generateUrl('incserve');
export const mainWebsite = generateUrl('website');
export const incserveStagingUrl = generateUrl('incserve');

export const subscribedProducts = (productData, hasProductAccess, userPermissionList, userData) => {
    let tmpArray = [];
    if (productData) {
        productData
            .filter((itm) => {
                const lowercaseName = itm?.name?.toLowerCase();
                return (
                    lowercaseName !== KEY_WORD?.INCSERVE &&
                    (itm?.status === 'Subscribed' || itm?.status === 'Cancelled')
                );
            })
            .map((itm) => {
                if (hasProductAccess(userPermissionList, itm?.subdomain.toLowerCase(), userData)) {
                    return tmpArray.push({ label: itm?.name, value: itm?._id });
                }
            });
    }
    return tmpArray;
};

export const permissionDenied = () => {
    toast.error('Permission Denied', { toastId: 'Permission Denied' });
};

export const checkInterviewPermissionAndTakeAction = (hasAccess, doAction) => {
    if (hasAccess) {
        doAction();
    } else {
        permissionDenied();
    }
};

export const getAssetSrc = (assetName) => {
    return `https://incruiter.blob.core.windows.net/frontend/frontend-account/assets/${assetName}`;
};

export const parseMarkdown = (markdownContent) => {
    // Replace Markdown syntax with HTML equivalents
    const htmlContent = markdownContent
        // Headings
        .replace(/^# (.+)$/gm, '<h1>$1</h1>')
        .replace(/^## (.+)$/gm, '<h2>$1</h2>')
        .replace(/^### (.+)$/gm, '<h3>$1</h3>')
        .replace(/^#### (.+)$/gm, '<h4>$1</h4>')
        .replace(/^##### (.+)$/gm, '<h5>$1</h5>')
        .replace(/^###### (.+)$/gm, '<h6>$1</h6>')

        // Bold and Italic
        .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.+?)\*/g, '<em>$1</em>')

        // Lists
        .replace(/^\s*-\s(.+)/gm, '<li>$1</li>')
        .replace(/^\s*1\.\s(.+)/gm, '<li>$1</li>')
        .replace(/^\s*\d+\.\s(.+)/gm, '<li>$1</li>')
        .replace(/<\/li><li>/g, '</li><li><ul>') // Wrap list items in <ul> tags
        .replace(/<\/li><\/ul><li>/g, '</li><li>') // Remove extra <ul> tags

        // Links
        .replace(/\[(.+?)\]\((\S+?)\)/g, '<a href="$2">$1</a>')

        // Images
        .replace(/!\[(.*?)\]\((.*?)\)/g, '<img alt="$1" src="$2" />')

        // Blockquotes
        .replace(/^\>(.*)$/gm, '<blockquote>$1</blockquote>')

        // Code blocks
        .replace(/```(.+?)\n([\s\S]+?)\n```/g, '<pre><code class="$1">$2</code></pre>')
        .replace(/``(.+?)``/g, '<code>$1</code>');

    return htmlContent;
};

export const toastMessage = (type, message) => {
    toast[type](message, { autoClose: '5000', toastId: message });
};

export const handleCopy = (content, msg) => {
    navigator.clipboard.writeText(content);
    toastMessage('success', msg);
};

export const getIncserveToken = Cookies.get(`incserveToken_${envMode}`) ?? '';
export const getAccessToken = Cookies.get(`token_${envMode}`) ?? '';
export const setAccessToken = (token) => {
    Cookies.set(`token_${envMode}`, token, {
        domain: window.location.hostname === KEY_WORD?.LOCALHOST ? KEY_WORD?.LOCALHOST : KEY_WORD?.INCRUITER_DOMIAN,
    });
};

export const clearCookies = () => {
    const cookieNames = ['token', 'incserveToken', 'iCuD', 'iCcD', 'iCpD', 'ic_p'];
    cookieNames.forEach((name) => {
        Cookies.remove(`${name}_${envMode}`, { path: '/', domain: window.location.hostname });
    });
};

export const clearOldCookies = () => {
    let cookieNames = [];
    const cookiesDevName = [
        'token_dev',
        'incserveToken_dev',
        'iCuD_dev',
        'iCcD_dev',
        'iCpD_dev',
        'ic_p_dev',
        'incD_dev',
    ];
    const cookiesTestName = [
        'token_test',
        'incserveToken_test',
        'iCuD_test',
        'iCcD_test',
        'iCpD_test',
        'ic_p_test',
        'incD_test',
    ];
    const cookiesProdName = [
        'token_prod',
        'incserveToken_prod',
        'iCuD_prod',
        'iCcD_prod',
        'iCpD_prod',
        'ic_p_prod',
        'incD_prod',
    ];
    if (envMode === 'dev') {
        cookieNames = [...cookiesTestName, ...cookiesProdName];
    } else if (envMode === 'test') {
        cookieNames = [...cookiesDevName, ...cookiesProdName];
    } else {
        cookieNames = [...cookiesTestName, ...cookiesDevName];
    }
    cookieNames.forEach((name) =>
        Cookies.remove(`${name}_${envMode}`, { path: '/', domain: window.location.hostname })
    );
};

export const noSelectPaths = [
    '/notification',
    '/my-profile',
    '/roles-subuser-list',
    '/accounts-billing',
    '/support',
    '/settings',
    '/product',
];
