import React from 'react';
import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
    Toast,
    localStorageEncrypt,
    mainWebsite,
    clearCookies,
    getAccessToken,
    clearOldCookies,
} from '../../utils/helper';
import { persistor } from '../store';

import {
    LOGIN_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    LOGOUT_USER,
    CONFIGURATION_AUTOINVITE,
    GET_CONFIGURATION_AUTO_INVITE,
    FETCH_API_TOKEN,
    FETCH_API_KEY,
    CONFIGURATION_PANELISTSLOTS,
    EMAIL_NOTIFICATION_CONFIG,
    GET_EMAIL_NOTIFICATION_CONFIG,
} from '../action/types';
import {
    loginUserFailure,
    loginUserSuccess,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    changePasswordSuccess,
    changePasswordFailure,
    logoutUserSuccess,
    logoutUserFailure,
    configurationAutoInviteSuccess,
    configurationAutoInviteFailure,
    getConfigurationAutoInviteSuccess,
    getConfigurationAutoInviteFailure,
    fetchApiTokenSuccess,
    fetchApiTokenFailure,
    fetchApiKeySuccess,
    fetchApiKeyFailure,
    configurationPanelistSlotsSuccess,
    configurationPanelistSlotsFailure,
    emailNotificationConfigSuccess,
    emailNotificationConfigFailure,
    getEmailNotificationConfigSuccess,
    getEmailNotificationConfigFailure,
} from '../action';
import AUTH from '../../utils/api/auth';
import NEWAUTH from '../../utils/api/newauth';
import FORGETPASSWORD from '../../utils/api/forgetpassword';
import USERS from '../../utils/api/users';
function* loginUserRequest(action) {
    try {
        const res = yield NEWAUTH.get('auth/oauth/verify-token', {
            headers: {
                Authorization: `Bearer ${getAccessToken}`,
            },
        });
        if ((res && res.status === 200) || res.status === 201) {
            yield put(loginUserSuccess(res.data.result.user));
            if (res.data?.result?.user?.isVerified) {
                if (
                    res.data?.result?.user?.userType === 'employer' ||
                    res.data?.result?.user?.userType === 'subemployer'
                ) {
                    clearOldCookies();
                    localStorageEncrypt('userData', JSON.stringify(res.data.result.user));
                    localStorageEncrypt('companyData', JSON.stringify(res.data?.result?.businessDetails));
                    localStorageEncrypt('permissions', JSON.stringify(res.data.result.permissions));
                    yield call(action.payload.callback, res.data?.result);
                } else {
                    yield put(loginUserFailure({ data: { message: 'Authorization Denied' } }));
                }
            } else {
                localStorageEncrypt('userData', JSON.stringify(res.data.result.user));
                yield call(action.payload.callback, res.data?.result);
            }
        }
    } catch (e) {
        yield put(loginUserFailure(e.response));
    }
}
function* forgotPasswordRequest(action) {
    try {
        const res = yield FORGETPASSWORD.get(`/email/${action.payload.email}`);
        if (res.status === 200) {
            yield put(forgotPasswordSuccess());
            yield call(action.payload.callback);
            if (res.data.result) {
                yield toast.success(
                    <Toast msg="A reset link has been sent to your email address. Please check your email and reset your password." />
                );
            } else {
                yield toast.error(
                    <Toast msg="Your email has not been registered with us. Please sign up to continue." />,
                    { toastId: 'Your email has not been registered with us. Please sign up to continue.' }
                );
            }
        }
    } catch (e) {
        yield put(forgotPasswordFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* resetPasswordRequest(action) {
    try {
        const res = yield FORGETPASSWORD.post(`forgot-password/reset`, {
            email: action.payload.email,
            code: action.payload.code,
            password: action.payload.password,
        });
        if (res.status === 200 || res.status === 201 || res.status === 204) {
            yield put(resetPasswordSuccess());
            yield call(action.payload.callback);
            yield toast.success(
                <Toast msg="Your password has been reset successfully. please login again with new password" />
            );
        }
    } catch (e) {
        yield put(resetPasswordFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* changePasswordRequest(action) {
    try {
        const res = yield AUTH.post(`auth/change-password`, {
            oldPassword: action.payload.oldPassword,
            newPassword: action.payload.password,
        });
        if (res.status === 201) {
            yield put(changePasswordSuccess());
            yield toast.success(<Toast msg="Your password has been changed successfully." />);
            yield call(action.payload.callback());
        }
    } catch (e) {
        yield put(changePasswordFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* configurationAutoInviteRequest(action) {
    try {
        const { percentageCriteria, checkCriteria, jdMandatory, callback } = action?.payload;
        const res = yield AUTH.post(`users/incbot/userSettings`, {
            percentageCriteria,
            checkCriteria,
            jdMandatory,
        });
        if (res.status === 201) {
            yield put(configurationAutoInviteSuccess(res.data?.result));
            yield toast.success(<Toast msg="Auto Invite changes saved." />);
            yield call(callback);
        }
    } catch (e) {
        yield put(configurationAutoInviteFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* logoutUserRequest(action) {
    try {
        const res = yield AUTH.patch(`auth/logout`);
        if (res.status === 201 || res.status === 200) {
            yield all([call(persistor.purge), put(logoutUserSuccess())]);
            localStorage.clear();
            clearCookies();
            persistor.pause();
            persistor.flush().then(() => {
                return persistor.purge();
            });
            yield toast.success(<Toast msg="Logged out successfully." />);
            window.location.replace(mainWebsite + '/employer/login');
        }
    } catch (e) {
        yield all([call(persistor.purge), put(logoutUserFailure())]);
        localStorage.clear();
        clearCookies();
        clearOldCookies();
        persistor.pause();
        persistor.flush().then(() => {
            return persistor.purge();
        });
        yield toast.success(<Toast msg="Logged out successfully." />);
        window.location.replace(mainWebsite + '/employer/login');
    }
}

function* getConfigurationAutoInviteRequest() {
    try {
        const res = yield AUTH.get(`users/incbot/userSettings`);
        if (res.status === 201 || res.status === 200) {
            yield put(getConfigurationAutoInviteSuccess(res.data?.result));
        }
    } catch (e) {
        yield put(getConfigurationAutoInviteFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* emailNotificationConfigRequest(action) {
    try {
        const { incBotEmailNotification, callback } = action?.payload;
        const res = yield AUTH.post(`users/v2/userSettings`, {
            incBotEmailNotification,
        });
        if (res.status === 201) {
            yield put(emailNotificationConfigSuccess(res.data?.result));
            yield call(callback);
        }
    } catch (e) {
        yield put(emailNotificationConfigFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}
function* getEmailNotificationConfigRequest() {
    try {
        const res = yield AUTH.get(`users/v2/userSettings`);
        if (res.status === 201 || res.status === 200) {
            yield put(getEmailNotificationConfigSuccess(res.data?.result));
        }
    } catch (e) {
        yield put(getEmailNotificationConfigFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* fetchApiTokenRequest() {
    try {
        const res = yield USERS.get(`employer/integration/generateToken`);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchApiTokenSuccess(res.data?.result?.accessToken));
        }
    } catch (e) {
        yield put(fetchApiTokenFailure());
    }
}
function* fetchApiKeyRequest() {
    try {
        const res = yield USERS.get(`employer/integration/generateApiKey`);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchApiKeySuccess(res.data?.result?.api_key));
        }
    } catch (e) {
        yield put(fetchApiKeyFailure());
    }
}
function* configurationPanelistSlotsRequest(action) {
    try {
        const res = yield AUTH.post(`users/incbot/userSettings`, {
            panelistSlotsType: action?.payload?.panelistSlotsType,
        });
        if (res.status === 201) {
            yield put(configurationPanelistSlotsSuccess(res.data?.result));
            yield toast.success(<Toast msg="Panelist slot type saved." />);
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(configurationPanelistSlotsFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

export function* watchLogoutUserAPI() {
    yield takeEvery(LOGOUT_USER, logoutUserRequest);
}

export function* watchLoginUserAPI() {
    yield takeEvery(LOGIN_USER, loginUserRequest);
}

export function* watchForgotPasswordAPI() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
}

export function* watchResetPasswordAPI() {
    yield takeEvery(RESET_PASSWORD, resetPasswordRequest);
}

export function* watchChangePasswordAPI() {
    yield takeEvery(CHANGE_PASSWORD, changePasswordRequest);
}

export function* watchConfiguartionAutoInviteAPI() {
    yield takeEvery(CONFIGURATION_AUTOINVITE, configurationAutoInviteRequest);
}

export function* watchGetConfiguartionAutoInviteAPI() {
    yield takeEvery(GET_CONFIGURATION_AUTO_INVITE, getConfigurationAutoInviteRequest);
}

export function* watchFetchApiTokenAPI() {
    yield takeEvery(FETCH_API_TOKEN, fetchApiTokenRequest);
}
export function* watchFetchApiKeyAPI() {
    yield takeEvery(FETCH_API_KEY, fetchApiKeyRequest);
}
export function* watchConfiguartionPanelistSlotsAPI() {
    yield takeEvery(CONFIGURATION_PANELISTSLOTS, configurationPanelistSlotsRequest);
}
export function* watchEmailNotificationConfigAPI() {
    yield takeEvery(EMAIL_NOTIFICATION_CONFIG, emailNotificationConfigRequest);
}
export function* watchGetEmailNotificationConfigAPI() {
    yield takeEvery(GET_EMAIL_NOTIFICATION_CONFIG, getEmailNotificationConfigRequest);
}

export default function* rootSaga() {
    yield all([
        watchLoginUserAPI(),
        watchForgotPasswordAPI(),
        watchResetPasswordAPI(),
        watchChangePasswordAPI(),
        watchLogoutUserAPI(),
        watchEmailNotificationConfigAPI(),
        watchGetEmailNotificationConfigAPI(),
        watchConfiguartionAutoInviteAPI(),
        watchGetConfiguartionAutoInviteAPI(),
        watchFetchApiTokenAPI(),
        watchFetchApiKeyAPI(),
        watchConfiguartionPanelistSlotsAPI(),
    ]);
}
